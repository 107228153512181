//APIS
import axios from 'apis/axios'

export const postTrackerGroup = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'tracker/group/list', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postTrackerStates = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'tracker/get_states', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postTrackerList = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'tracker/list', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postTrackerCount = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'tracker/counter/value/list', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}

export const postSendCommand = async (
  inputSignal, 
  inputBodyParams, 
  inputAxios,
) => {
  try {
    const ax = inputAxios ?? axios
    const response = await ax.post(
      'tracker/raw_command/send', 
      {
        ...inputBodyParams,
      }, 
      { 
        signal: inputSignal,
      },
    )
  
    return response
  } catch (error) {
    if (error.message === 'canceled') return { status: 'Canceled' }
    else if (!error.response) return { status: 'No Server Response' }
    else return error.response
  }
}